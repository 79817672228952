import React from 'react';

const Hero = () => {
    return (
        <div className="bg-blue-600 text-white text-center py-4">
            <h2 className="text-4xl font-bold my-2 py-2">PDF Dönüştürücü</h2>
            <h3 className="text-xl font-bold my-2 py-2">Resimden PDF'e, PDF'den resime hızlıca dönüştürün!</h3>
            <p className="text-l font-bold my-2 py-2" >
                PDFCİ  verileri kaydetmeyen, güvenli ve hızlı bir çözümdür.
                <br />
                Resimlerinizi PDF formatına kolayca dönüştürün veya PDF belgelerinizi yüksek kalitede görüntülere dönüştürün.
            </p>
            <h2 className='text-xl font-bold my-2 py-2'>
                Dönüşüm işlemi tamamen kullanıcıya ait olup, gizliliğinizi koruyarak çalışır. </h2>
        </div>
    );
};

export default Hero;
