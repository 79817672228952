import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className=" bg-gray-800 text-white text-center py-4 mt-10">
            <h2 className='bg-gray-500 text-xl py-1'>PDF Dönüştürücü</h2>
            <p className='py-1 my-1'>&copy; {new Date().getFullYear()} Tüm hakları saklıdır.</p>

            {/* İletişim için e-posta linki */}
            <p className="py-1">
                İletişim için <a href="mailto:devrimq@gmail.com" className="text-gray-400 hover:text-white">devrimq@gmail.com</a>
            </p>

            <Link to="/">
                <img src={`${process.env.PUBLIC_URL}/logo192.png`} alt="Logo" className="h-10 mx-auto mb-2 px-1 py-1" />
            </Link>
        </footer>
    );
};

export default Footer;
